/*== 
 * React & TailwindCSS Portfolio Main CSS File
 * Powered by: @realstoman
*/

body {
	font-family: 'lato';

}

/* Fonts */


/* Scroll to top style */
.scrollToTop {
	position: fixed;
	width: 100%;
	align-items: center;
	height: 20px;
	justify-content: center;
	z-index: 999;
	cursor: pointer;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 0.9;
}

.scrollToTop:hover {
	opacity: 1;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.5;
	}
}
