@tailwind base;
@tailwind components;
@tailwind utilities;

.img-src {
  @apply grid min-h-screen place-items-center;
}
.heading-frame {
  @apply grid shadow-[0_3px_6px_rgba(0,0,0,0.4)] grid-cols-[max-content_auto] grid-rows-[auto_max-content] bg-cover text-[white] w-[400px] h-[300px] p-8 before:row-[1_/_2] before:border-r-[none] before:border-b-[none] after:row-[1_/_span_2] after:border-l-[none];
  background: #333
    url(https://images.unsplash.com/photo-1497406703182-f805b8fbba89?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7598c4ba7994f83dab58ae34f1abd023&auto=format&fit=crop&w=1584&q=80)
    center;
}
.heading-frame:before,
.heading-frame:after {
  @apply content-[""] block w-full h-full border border-solid border-[white];
}
.heading-frame h2 {
  @apply mb-[-0.5rem] text-[2rem] max-w-full ml-0 mr-4 mt-4;
  font-family: "Pragati Narrow", sans-serif;
}

/*  */
  .buttonAnim {
    @apply w-[250px] h-[50px] border text-xl cursor-pointer flex items-center justify-center relative z-0 transition-[1s]  border-solid border-[#E8E7E0] before:h-[50px] before:w-[230px] after:w-[250px] after:h-[30px] hover:before:w-0 hover:after:h-0;
  }
  .buttonAnim::before,
  .buttonAnim::after {
    @apply absolute z-[-1] transition-[1s] content-[''] duration-1000;
background: #19536C;
  }
 